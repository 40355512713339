// src/App.js
import React, { useEffect, useRef, useState } from "react";
import Gallery from "./Gallery.tsx";
import Header from "./Header.tsx";
import styled from "styled-components";
import moment from "moment";
import { useAuth } from "../../contexts/authContext/index.jsx";
import PageHeader from "../PageHeader/index.jsx";

const AppContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const AppHeader = styled.header`
  background-color: #282c34;
  min-height: 35px;
  margin-bottom: 20px;
  font-size: 30px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

function Home() {
  const [images, setImages] = useState<any[]>([]);
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [filter, setFilter] = useState("all");
  const [editAllowedEmails, setEditAllowedEmails] = useState<string[]>([]);
  const [groupByDate, setGroupByDate] = useState(true);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const apiUrl =
        "https://birthday-gift-ac200-default-rtdb.firebaseio.com/images.json";
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const imagesArray: any[] = [];
          // const imageIdsArray = Object.keys(data) ?? []
          Object.entries(data).forEach((d) => {
            imagesArray.push({ ...(d?.[1] ?? {}), id: d?.[0] });
          });
          imagesArray.sort((a, b) => {
            return (
              moment(b.date, "DD/MM/YYYY").unix() -
              moment(a.date, "DD/MM/YYYY").unix()
            );
          });
          const imageIdsArray = imagesArray.map((img) => img.id);
          // imagesArray.reverse();
          // imageIdsArray.reverse()
          setImages(imagesArray);
          setImageIds(imageIdsArray);
        })
        .catch((error) => console.error(error));
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      const apiUrl =
        "https://birthday-gift-ac200-default-rtdb.firebaseio.com/edit_allowed.json";
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const emails = data.split("/");
          if (emails.length) setEditAllowedEmails(emails);
        })
        .catch((error) => console.error(error));
    };

    fetchPermissions();
  }, []);

  const addImage = (url, date) => {
    const newImage = {
      url,
      date,
      favorite: false,
    };
    setImages((prevState) => [newImage, ...prevState]);
  };

  const toggleFavorite = (image) => {
    let favIndex = -1;
    let newFav = false;
    setImages(
      images.map((img, index) => {
        if (img === image) {
          favIndex = index;
          newFav = !img.favorite;
          img = { ...img, favorite: newFav };
        }
        return img;
      })
    );
    if (favIndex !== -1) {
      const favImageId = imageIds?.[favIndex];
      const apiUrl = `https://birthday-gift-ac200-default-rtdb.firebaseio.com/images/${favImageId}.json`;
      fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ favorite: newFav }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.error(error));
    }
  };

  const deleteImage = (index) => {
    setImages([...images].filter((img, ind) => ind !== index));
    const delImageId = imageIds?.[index];
    if (delImageId) {
      const apiUrl = `https://birthday-gift-ac200-default-rtdb.firebaseio.com/images/${delImageId}.json`;
      fetch(apiUrl, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.error(error));
    }
  };

  const { currentUser } = useAuth();

  const can_add = editAllowedEmails.includes(currentUser.email as string);
  const can_edit = editAllowedEmails.includes(currentUser.email as string);
  const can_delete = editAllowedEmails.includes(currentUser.email as string);

  return (
    <AppContainer style={{ backgroundColor: "#282c34" }}>
      <PageHeader />
      <AppHeader>
        <h1>Our Gallery</h1>
      </AppHeader>
      <Header
        can_add={can_add}
        onAddImage={addImage}
        onFilterChange={setFilter}
        onGroupChange={setGroupByDate}
      />
      <Gallery
        can_edit={can_edit}
        can_delete={can_delete}
        images={images}
        onToggleFavorite={toggleFavorite}
        onDeleteImage={deleteImage}
        filter={filter}
        groupByDate={groupByDate}
      />
    </AppContainer>
  );
}

export default Home;
