import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAIGa40nK9Z2-l2mHyvwtCCfNwNcN-baxk",
    authDomain: "birthday-gift-ac200.firebaseapp.com",
    projectId: "birthday-gift-ac200",
    storageBucket: "birthday-gift-ac200.appspot.com",
    messagingSenderId: "103710017653",
    appId: "1:103710017653:web:1d2e7d709b41ca719e503c"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
