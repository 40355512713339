
import React from "react";
import Header from "./components/PageHeader/index.jsx";

import { AuthProvider } from "./contexts/authContext";
import GalleryRoutes from "./containers/GalleryRoutes.tsx";

function App() {
  return (
    <AuthProvider>
      {/* <Header /> */}
      <GalleryRoutes/>
    </AuthProvider>
  );
}

export default App;
