// src/components/Gallery.js
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { FaStar, FaRegStar, FaTrash, FaPlay } from "react-icons/fa";
import moment from "moment";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  padding: 20px;
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeading = styled.h2`
  text-align: left;
  margin: 10px 0;
`;

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
  margin: 10px;
  cursor: pointer;

  img,
  video {
    width: 200px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    @media (max-width: 600px) {
      width: 100px;
    }
  }

  video {
    opacity: 0.5;
  }

  .favorite-icon,
  .delete-icon,
  .play-icon {
    position: absolute;
    top: 10px;
    color: gold;
  }

  .favorite-icon {
    right: 30px;
  }

  .play-icon {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .delete-icon {
    right: 10px;
  }
`;

const ModalImage = styled.img`
  width: 80%;
  height: auto;
  margin: auto;
  display: block;

  @media (max-width: 600px) {
    width: 95%;
  }
`;

const ModalVideo = styled.video`
  width: 80%;
  height: auto;
  margin: auto;
  display: block;

  @media (max-width: 600px) {
    width: 95%;
  }
`;

Modal.setAppElement("#root");

const Gallery = ({
  can_edit,
  can_delete,
  images,
  onToggleFavorite,
  onDeleteImage,
  filter,
  groupByDate,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  const [selectedImageGroup, setSelectedImageGroup] = useState<string | null>(
    null
  );

  const openModal = (index: number, date?: string | undefined) => {
    if (date) {
      setSelectedImageGroup(date);
      setSelectedImage(groupedImages[date][index]);
    } else {
      setSelectedImage(filteredImages[index]);
    }
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
    setSelectedImageGroup(null);
    setSelectedImage(null);
  };

  const filteredImages = images.filter((image) => {
    if (filter === "favorites") return image.favorite;
    if (filter === "non-favorites") return !image.favorite;
    return true;
  });

  const groupedImages = filteredImages.reduce((groups, image) => {
    const date = image.date;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(image);
    return groups;
  }, {});

  const RenderModal = ({ selectedImage, onSwipeLeft, onSwipeRight }) => {
    const touchStartX = useRef(null);
    const touchEndX = useRef(null);

    const handleTouchStart = (e) => {
      touchStartX.current = e.targetTouches[0].clientX;
    };

    const handleTouchMove = (e) => {
      touchEndX.current = e.targetTouches[0].clientX;
    };

    const handleTouchEnd = () => {
      if (!touchStartX.current || !touchEndX.current) return;

      const deltaX = touchStartX.current - touchEndX.current;
      const swipeThreshold = 50; // Adjust this value to determine swipe sensitivity

      if (Math.abs(deltaX) > swipeThreshold) {
        if (deltaX > 0) {
          onSwipeLeft();
        } else {
          onSwipeRight();
        }
      }

      touchStartX.current = null;
      touchEndX.current = null;
    };
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {selectedImage &&
            (selectedImage.url.endsWith(".mp4") ? (
              <ModalVideo autoPlay controls src={selectedImage.url} />
            ) : (
              <ModalImage src={selectedImage.url} alt="Selected Art" />
            ))}
        </div>
      </Modal>
    );
  };

  return (
    <GalleryContainer>
      {groupByDate ? (
        Object.keys(groupedImages).map((date) => (
          <DateGroup key={date}>
            <DateHeading style={{ color: "white" }}>
              {moment(date, "DD/MM/YYYY").format("DD MMMM YYYY")}
            </DateHeading>
            <ImageGrid>
              {groupedImages[date].map((image, index) => {
                return (
                  <ImageContainer
                    key={index}
                    onClick={() => openModal(index, date)}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        background: "black",
                      }}
                    >
                      {image.url.endsWith(".mp4") ? (
                        <video src={image.url} />
                      ) : (
                        <img src={image.url} alt={`Art ${index + 1}`} />
                      )}
                    </div>
                    {can_edit && (
                      <div
                        className="favorite-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          onToggleFavorite(image);
                        }}
                      >
                        {image.favorite ? <FaStar /> : <FaRegStar />}
                      </div>
                    )}
                    {can_delete && (
                      <div
                        className="delete-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteImage(index);
                        }}
                      >
                        <FaTrash />
                      </div>
                    )}
                    {image.url.endsWith(".mp4") && (
                      <div className="play-icon">
                        <FaPlay />
                      </div>
                    )}
                  </ImageContainer>
                );
              })}
            </ImageGrid>
          </DateGroup>
        ))
      ) : (
        <ImageGrid>
          {filteredImages.map((image, index) => (
            <ImageContainer key={index} onClick={() => openModal(index)}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  background: "black",
                }}
              >
                {image.url.endsWith(".mp4") ? (
                  <video src={image.url} />
                ) : (
                  <img src={image.url} alt={`Art ${index + 1}`} />
                )}
              </div>
              <div
                className="favorite-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleFavorite(image);
                }}
              >
                {image.favorite ? <FaStar /> : <FaRegStar />}
              </div>
              <div
                className="delete-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteImage(image);
                }}
              >
                <FaTrash />
              </div>
              {image.url.endsWith(".mp4") && (
                <div className="play-icon">
                  <FaPlay />
                </div>
              )}
            </ImageContainer>
          ))}
        </ImageGrid>
      )}

      <RenderModal
        selectedImage={selectedImage}
        onSwipeRight={() => {
          if (selectedImageGroup) {
            if (
              (selectedImageIndex || selectedImageIndex === 0) &&
              selectedImageIndex > 0
            ) {
              openModal(selectedImageIndex - 1, selectedImageGroup);
            } else {
              Object.keys(groupedImages).forEach((date, index) => {
                if (date === selectedImageGroup && index > 0) {
                  const newGroup = Object.keys(groupedImages)[index - 1];
                  openModal(groupedImages[newGroup].length - 1, newGroup);
                }
              });
            }
          } else if (
            (selectedImageIndex || selectedImageIndex === 0) &&
            selectedImageIndex > 0
          ) {
            openModal(selectedImageIndex - 1);
          }
        }}
        onSwipeLeft={() => {
          if (selectedImageGroup) {
            if (
              (selectedImageIndex || selectedImageIndex === 0) &&
              selectedImageIndex + 1 < groupedImages[selectedImageGroup].length
            ) {
              openModal(selectedImageIndex + 1, selectedImageGroup);
            } else {
              Object.keys(groupedImages).forEach((date, index) => {
                if (
                  date === selectedImageGroup &&
                  index + 1 < Object.keys(groupedImages).length
                ) {
                  const newGroup = Object.keys(groupedImages)[index + 1];
                  openModal(0, newGroup);
                }
              });
            }
          } else if (
            (selectedImageIndex || selectedImageIndex === 0) &&
            selectedImageIndex + 1 < filteredImages.length
          ) {
            openModal(selectedImageIndex + 1);
          }
        }}
      />
    </GalleryContainer>
  );
};

export default Gallery;
