import { useRoutes } from "react-router-dom";
import Home from "../components/home/index.tsx";
import { useAuth } from "../contexts/authContext";
import Login from "../components/auth/login";
import Register from "../components/auth/register";
import React from "react";

const GalleryRoutes = () => {
    const routesArray = [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/register",
          element: <Register />,
        },
      ];
      const { userLoggedIn } = useAuth()
    
      if(userLoggedIn){
        routesArray.push({
          path: "/home",
          element: <Home />,
        })
      }
    
      let routesElement = useRoutes(routesArray);    
    return(
        <div className="w-full h-screen flex flex-col" style={{justifyContent: 'center'}}>{routesElement}</div>
    )
}

export default GalleryRoutes