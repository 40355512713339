import React, { FC, useState, useRef, Fragment, useEffect, ReactElement } from 'react';
import classnames from 'classnames';
import './FileDragDrop.css';
// import * as _ from 'lodash';
// import { notify } from 'react-notify-toast';

type FileDragDropProps = {
	className?: string;
	onFileSelect?: any;
	onMultiFileSelect?: any;
	fileExtensions?: string[];
	fileSize?: number;
	validation?: FileValidation;
	defaultFileName?: string;
	disabled?: boolean;
	file?: File;
	iconImage?: ReactElement;
	previewView?: any;
	previewEdit?: () => void;
	previewRemove?: any;
	files?: (ImageFileProps | VideoFileProps | null)[];
	maxFilesLimit?: number;
	InputComponent?: ReactElement;
	shouldHidePreviewBox?: boolean;
	hideOriginalFileName?: boolean;
};
interface FileValidation {
	video: {
		max_height: number;
		max_width: number;
	};
}

interface ImageFileProps {
	file?: File;
	file_name: string;
	answer: string;
	answer_url?: string;
}

interface VideoFileProps {
	file?: File;
	file_name: string;
	answer: string;
	answer_url?: string;
}

const defaultFileExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4'];
const defaultFileSize = 5 * 1024 * 1024;

const getVideoResolution = (file: any) => {
	return new Promise((resolve) => {
		const video = document.createElement('video');

		// this is important
		video.autoplay = true;
		video.muted = true;
		video.src = URL.createObjectURL(file);
		video.onloadeddata = () => {
			return resolve({
				height: video.videoHeight,
				width: video.videoWidth,
			});
		};
	});
};

const FileDragDrop: FC<FileDragDropProps> = (props: FileDragDropProps) => {
	const inputFile = useRef(null);
	const [isDragOver, setIsDragOver] = useState(false),
		[selectedFile, setSelectedFile] = useState<File | null>(props?.file || null),
		[selectedFiles, setSelectedFiles] = useState<(ImageFileProps | VideoFileProps | null)[]>(props?.files || []),
		[fileError, setFileError] = useState(false),
		[fileSizeError, setFileSizeError] = useState(false),
		[fileValidationError, setFileValidationError] = useState(false),
		[fileValidationErrorMsg, setFileValidationErrorMsg] = useState(''),
		[defaultFileName, setDefaultFileName] = useState(props?.defaultFileName || '');
	const [singleFileNameState, setSingleFileNameState] = useState<string>('');

	const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'raw', 'cr2', 'nef', 'heif', 'heic', 'ico'];

	const videoFormats = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'mpeg', 'mpg', '3gp', 'asf'];

	useEffect(() => {
		props.file && setSelectedFile(props.file);
	}, [props.file]);

	useEffect(() => {
		props.files && setSelectedFiles(props.files);
	}, [props.files]);

	useEffect(() => {
		props.defaultFileName && setDefaultFileName(props.defaultFileName);
	}, [props.defaultFileName]);

	useEffect(() => {
		let singleFileName: string | undefined = selectedFile?.name || defaultFileName || '';
		const extension: string = singleFileName ? singleFileName.split('.').pop()?.toLowerCase() || '' : '';
		if (singleFileName.length > 0) {
			if (imageFormats.includes(extension)) {
				singleFileName = `Image 1`;
			} else if (videoFormats.includes(extension)) {
				singleFileName = `Video 1`;
			}
		} else {
			singleFileName = `File 1`;
		}
		setSingleFileNameState(singleFileName);
	}, [selectedFile?.name, defaultFileName]);

	const { className, onFileSelect, fileExtensions, fileSize, disabled = false } = props;

	const valid_extensions = fileExtensions || defaultFileExtensions,
		valid_file_size = fileSize || defaultFileSize;

	const handleMultipleFiles = async (fileList: FileList) => {
		let multipleSelectedFiles: (File | null)[] = [];

		// const selectedFilesCount: number = fileList.length;
		// const existingfilesCount: number = props?.files?.length || 0;
		// const maxFilesLimit: number = props.maxFilesLimit || 5;

		let fileListIterationLimit: number = fileList.length;
		// if (selectedFilesCount + existingfilesCount > maxFilesLimit) {
		// 	fileListIterationLimit = maxFilesLimit - existingfilesCount;
		// 	// notify.show(`Maximum of ${props.maxFilesLimit || 5} Files can be Added`, "error")
		// }

		for (let i = 0; i < fileListIterationLimit; i++) {
			const file = fileList[i];

			if (disabled) {
				continue;
			}

			const file_type = file?.type || '';

			if (valid_extensions.includes(file_type)) {
				// if (file.size > valid_file_size) {
				// 	file && setFileSizeError(true);
				// 	setSelectedFile(null);
				// 	multipleSelectedFiles.push(null);
				// 	continue;
				// }

				if (props?.validation?.video) {
					const video_resolution: any = await getVideoResolution(file);
					const video_height = video_resolution?.height || 0;
					const video_width = video_resolution?.width || 0;

					if (video_height > (props?.validation?.video?.max_height || 0) && video_width > (props?.validation?.video?.max_width || 0)) {
						setFileValidationError(true);
						setSelectedFile(null);
						multipleSelectedFiles.push(null);
						setFileValidationErrorMsg(`Max Video Resolution: ${props?.validation?.video?.max_width} x ${props?.validation?.video?.max_height}`);
						continue;
					}
				}

				setSelectedFile(file);
				setFileSizeError(false);
				file && setFileError(false);
				setFileValidationError(false);
				setFileValidationErrorMsg('');

				multipleSelectedFiles.push(file);
			} else {
				setSelectedFile(null);
				setFileSizeError(false);
				setDefaultFileName('');
				file && setFileError(true);
				multipleSelectedFiles.push(null);
			}
		}

		props.onMultiFileSelect(multipleSelectedFiles);
		setIsDragOver(false);
	};

	const handleFileChange = (e: any) => {
			const selectedFiles = e?.target?.files;

			if (selectedFiles) {
				// Cast selectedFiles to FileList explicitly
				handleMultipleFiles(selectedFiles as FileList);
			}
	};

	const onFileSelectClick = (e: any) => {
		if (disabled) {
			return false;
		}

		if (inputFile?.current) {
			// @ts-ignore
			inputFile?.current?.click();
		}
	};

	const DNDBox = () => (
		<div >
			<div
				// className={classnames(
				// 	"file_input",
				// 	"file_input__is_valid",
				// 	isDragOver ? "file_input__hover" : '',
				// 	className,
				// 	disabled ? "file_input__disabled" : '',
				// 	'px-5 py-4'
				// )}
				onDrop={(e) => {
					e.preventDefault();
					e.stopPropagation();

						const selectedFiles = e?.dataTransfer?.files;

						if (selectedFiles) {
							// Cast selectedFiles to FileList explicitly
							handleMultipleFiles(selectedFiles);
						}
				}}
				onDragOver={(e) => {
					e.stopPropagation();
					e.preventDefault();
					setIsDragOver(true);
				}}
				onDragEnter={(e) => {
					setIsDragOver(true);
				}}
				onDragLeave={(e) => {
					setIsDragOver(false);
				}}
				onClick={onFileSelectClick}
			>
					<span style={{border: '1px solid', borderRadius: '6px', padding: '5px', display: 'block', height: '36px'}}>
						<button type="submit">Add Image</button>
					</span>
			</div>
		</div>
	);

	return (
		<span>
			{props.InputComponent ? <span onClick={onFileSelectClick}>{props.InputComponent}</span> : DNDBox()}

			<input
				type="file"
				id="file"
				ref={inputFile}
				style={{ display: 'none' }}
				accept={valid_extensions.join(', ')}
				onChange={handleFileChange}
				disabled={disabled}
				multiple={true}
			/>

			{/* Errors */}
			{fileError && <span className={classnames("file_input__placeholder_file_error_text")}>Invalid File Type</span>}
			{fileSizeError && <span className={classnames("file_input__placeholder_file_error_text")}>Max file size: {valid_file_size / (1024 * 1024)}MB</span>}
			{fileValidationError && <span className={classnames("file_input__placeholder_file_error_text")}>{fileValidationErrorMsg}</span>}

		</span>
	);
};

export default FileDragDrop;
