// src/components/Header.js
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FileDragDrop from '../FileDragDrop/FileDragDrop.tsx';
import moment from 'moment';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #282c34;
  color: white;
`;

// const ImageForm = styled.form`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   input, button {
//     margin: 10px;
//   }
// `;

const FilterOptions = styled.div`
  display: flex;
  justify-content: center;

  select, button {
    margin: 0 10px;
  }
`;

const Header = ({ can_add, onAddImage, onFilterChange, onGroupChange }) => {
  const [filter, setFilter] = useState('all');
  const [groupByDate, setGroupByDate] = useState(true);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const saveToFirebase = (values) => {
    const apiUrl = 'https://birthday-gift-ac200-default-rtdb.firebaseio.com/images.json';
    fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            public_id: values.public_id,
            date: moment().format('DD/MM/YYYY'),
            favorite: false,
            url: values.url,
          })
        })

        onAddImage(values.url, moment().format('DD/MM/YYYY'))
  }

  const handleFilesSubmit = async (files: any) => {
    // const files = Array.from(e.dataTransfer.files);
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    if (files && files.length > 0) {
      const url = `https://api.cloudinary.com/v1_1/dc8jcoct4/upload`;
      for (let file of files) {
        try {
          const formData = new FormData();
          const fields = {
            file,
            upload_preset: 'together',
            tags: ['myphotoalbum-react'],
            multiple: true,
            resource_type: 'image',
          };

          Object.entries(fields).forEach(([key, value]) => {
            //@ts-ignore
            formData.append(key, value);
          });

          const options = {
            method: 'POST',
            body: formData,
            signal: abortControllerRef.current.signal,
          };
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error('Failed to execute file upload via the Fetch API');
          }
          const json = await response.json();
        //   const secureUrl = json.secure_url;
        //   const previewUrl = secureUrl.replace(
        //     '/upload/',
        //     '/upload/w_400/f_auto,q_auto/'
        //   );
          saveToFirebase(json);

        } catch (error: any) {
          if (error.name !== 'AbortError') {
            console.error(error);
          }
        }
      }
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    onFilterChange(e.target.value);
  };

  const handleGroupChange = () => {
    const newGroupByDate = !groupByDate;
    setGroupByDate(newGroupByDate);
    onGroupChange(newGroupByDate);
  };

  return (
    <HeaderContainer>
      {can_add && <FileDragDrop
        files={[]}
        shouldHidePreviewBox={true}
        onMultiFileSelect={handleFilesSubmit}
      />}
      <FilterOptions style={window.innerWidth < 650 ? {flexDirection: 'column-reverse'} : {}}>
        <select value={filter} onChange={handleFilterChange} style={{backgroundColor: "#282c34", border: '1px solid', borderRadius: '6px', padding: '5px'}}>
          <option value="all">All</option>
          <option value="favorites">Favorites</option>
          <option value="non-favorites">Non-Favorites</option>
        </select>
        <button style={{border: '1px solid', borderRadius: '6px', padding: '5px', marginBottom: `${window.innerWidth < 650 ? '6px' : '0'}`}} onClick={handleGroupChange}>
          {groupByDate ? 'Ungroup by: Date' : 'Group by: Date'}
        </button>
      </FilterOptions>
    </HeaderContainer>
  );
};

export default Header;
